<template>
  <base-view
    title="Visualizar traspasos"
    description="Visualiza la información de los traspasos pendientes, autorizados o rechazados"
    background="bg-gradient-purple-to-black"
    icon="repeat"
    header-type="overlap"
  >
    <b-card no-body>
      <b-card-header>
        <div class="row align-items-center">
          <div class="col">
            <check-authorization :requiresAuthorizations="['acceso periodos fiscales']">
              <router-link :to="{ name: 'PeriodosFiscales' }">
                <b-button size="sm">
                  Cambiar periodo fiscal activo
                </b-button>
              </router-link>
            </check-authorization>
          </div>
          <div class="col">
            <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
          </div>
        </div>
      </b-card-header>

      <div class="row py-1 p-4">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Filtrar por</b-input-group-text>
          </template>

          <b-form-select
            v-model="selected"
            :options="options"
            @input="onChangeEstado"
          >
          </b-form-select>
        </b-input-group>
      </div>

      <b-overlay :show="Boolean(loading)">
        <template #overlay>
          <loading></loading>
        </template>
        <div class="row my-1 p-4">
          <b-table
            :fields="fields"
            :items="traspasos"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell(id)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ data.item }}
              </div>
            </template>
            <template #cell(concepto)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ data.item.concepto }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
              </div>
            </template>
            <template #cell(folio)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ data.item.folio }}</b-badge>
              </div>
            </template>
            <template #cell(mes)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="secondary" class="ml-2">
                  {{ getMonthById(data.item.mes).value }}
                </b-badge>
              </div>
            </template>
            <template #cell(periodo_fiscal_id)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ data.item.periodo_fiscal_id }}</b-badge>
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-dark text-center text-sm w-100">
                {{ $formatNumber(data.item.total) }}
              </div>
            </template>
            <template #cell(ampliaciones)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ $formatNumber(data.item.ampliaciones) }}
              </div>
            </template>
            <template #cell(reducciones)="data">
              <div class="text-danger text-center text-sm w-100">
                {{ $formatNumber(data.item.reducciones) }}
              </div>
            </template>
            <template #row-details="row">
              <traspasos-movimientos-list :traspaso_id="row.item.id"></traspasos-movimientos-list>
            </template>
            <template #cell(acciones)="row">
              <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
                <b-button
                  class="mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="info"
                  @click="
                    row.toggleDetails()
                    onShowMovimientos(row.item.id)
                  "
                >
                  Ver Movimientos
                </b-button>
                <check-authorization :requiresAuthorizations="['descargar acuse traspaso']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="green"
                    @click="downloadAcuse(row.item)">
                    Acuse
                  </b-button>
                </check-authorization>
              </div>
            </template>
          </b-table>
        </div>
      </b-overlay>
    </b-card>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import TraspasosMovimientosList from '@/components/Traspasos/TraspasosMovimientosList'

export default {
  name: 'VisualizarTraspasosView',

  components: {
    TraspasosMovimientosList
  },

  data () {
    return {
      fields: ['folio', 'fecha', 'concepto', 'total', 'ampliaciones', 'reducciones', 'acciones'],

      loading: false,

      selectedTraspaso: null,

      selected: 0,

      options: [
        { text: 'Pendientes', value: 0 },
        { text: 'Autorizados', value: 1 },
        { text: 'Rechazados', value: 2 },
        { text: 'Enviados', value: 3 }
      ],

      traspasos: null
    }
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById'])
  },

  mounted () {
    this.onChangeEstado(0)
  },

  methods: {
    async onChangeEstado (value) {
      this.loading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      const traspasos = await this.$store.dispatch('traspasoModule/listar', {
        periodo_fiscal_id: this.periodoActivo.id,
        autorizar: false,
        autorizado: value
      })

      this.traspasos = traspasos.data
      this.loading = false
    },

    onShowMovimientos (id) {
      this.selectedTraspaso = this.traspasos.find(el => el.id === id)
    },

    async downloadAcuse (item) {
      // Verificamos si se encuentra autorizado
      if (item.autorizado !== 1) {
        this.$notify({ error: true, message: 'El traspaso no se encuentra autorizado, no es posible descargar el acuse' })
        return
      }
      const getval = await this.$store.dispatch('traspasoModule/downloadAcuse', item.id)
      if (!getval.error) {
        await this.$onDownload(getval, `acuse.${item.folio}.pdf`)

        this.$notify({ message: `Solicitud de descarga de acuse de traspaso con folio ${item.folio}.` })
      } else {
        this.$notify({ error: true, message: getval.message })
      }
    }
  }
}
</script>
